import {
    Box,
    Container,
    Typography,
} from '@material-ui/core';
import React from 'react';
import InfoCard from "../element/InfoCard";

export const Info: React.FC = () => (
    <>
        <Box mt={16}>
            <Container component="main" maxWidth="sm">
                <Box display="flex">
                    <Box m="auto">
                        <Typography variant="h4">お知らせ</Typography>
                    </Box>
                </Box>
                <Box mt={4}>
                    <InfoCard/>
                </Box>
            </Container>
        </Box>
    </>
)
