import {
    Box,
} from '@material-ui/core';
import React from 'react';


export const Footer: React.FC = () => (
    <>
        <Box mt={8} />
    </>
)
