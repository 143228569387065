import React from 'react';
import './App.css';
import '@material-ui/core/Button';
import Header from './component/layout/Header';
import Hero from './component/layout/Hero';
import { Info } from "./component/layout/Info";
import { CompanyInfo } from "./component/layout/CompanyInfo";
import { Footer } from "./component/layout/Footer";

function App() {
    return (
        <div>
            <Header/>
            <Hero/>
            <Info/>
            <CompanyInfo/>
            <Footer/>
        </div>
    );
}

export default App;
