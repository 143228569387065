import {
  Box,
  Button,
  Container,
  Typography,
} from '@material-ui/core';
import React from 'react';

export default function Hero() {
  return (
    <div>
      <Container component="main" maxWidth="sm">
        <Box mt={5}>
          <Typography variant="h4">AnyPercent合同会社</Typography>
        </Box>
        <Box mt={5}>
          <Typography variant="h6">
            iOSアプリ / Androidアプリ / Webサービスの開発を行っています。
          </Typography>
        </Box>
        <Box mt={2}>
          <Button
            variant="outlined"
            color="primary"
            href="https://forms.gle/J5n61hakbbHgwqeB7"
            target="_blank"
          >
            お問い合わせ
          </Button>
        </Box>
      </Container>
    </div>
  );
}
