import {
    Box,
    Button,
    Container, TableBody, TableCell, TableContainer, TableRow,
    Typography,
} from '@material-ui/core';
import React from 'react';

type Props = {
    title: string;
    content: string;
};

const rows: Array<Props> = [
    {
        title: "会社名",
        content: "AnyPercent合同会社"
    },
    {
        title: "設立",
        content: "2021年1月"
    },
    {
        title: "事業内容",
        content: "iOSアプリ開発 / Androidアプリ開発 / Webサービス開発"
    }
]

export const CompanyInfo: React.FC = () => (
    <>
        <Box mt={16}>
            <Container component="main" maxWidth="sm">
                <Box display="flex">
                    <Box m="auto">
                        <Typography variant="h4">会社情報</Typography>
                    </Box>
                </Box>
                <Box mt={2}>
                    <TableContainer>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.title}>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="subtitle1">
                                            {row.title}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body1">
                                            {row.content}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </TableContainer>
                </Box>
                <Box mt={4}>
                    <Button
                        variant="outlined"
                        color="primary"
                        href="https://forms.gle/5hPpTD4c1YVxV6UM8"
                        target="_blank"
                    >
                        お問い合わせ
                    </Button>
                </Box>
            </Container>
        </Box>
    </>
)
